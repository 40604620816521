@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Quicksand:wght@700&display=swap');


.App {
  text-align: center;
  font-family: "quicksand";
  color: rgb(255, 255, 255);
  height: 100%;
}

* {
  box-sizing: border-box;
}

body, html {
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
  
  }

.moving-background {
  color: rgb(0, 0, 0);
  background-repeat: no-repeat;
  min-height: 100%;
  min-width: 100%;
}

.announce-text {
  text-align: center;
  border-radius: 10px;
  width: 72%;
  color: white;
  margin-top: 4%;
  margin: auto;
  margin-bottom: 4%;
  padding-top: 2%;
  font-size: 24px;
}

.text-size {
  text-align: center;
  font-size: 48px;
  background-color: rgba(65, 63, 63, 0.781);
  border-radius: 25px;
  width: 36vw;
  margin: auto;

}

.mint-box {
  display: inline-block;
  color: white;
  padding-bottom: 2%;
  padding-right: 4%;
  text-indent: -5vw;
}

.mint-box-main {
  padding-bottom: 8vh;
}

.mint-button {
  color: white;
  background-color: rgba(170, 5, 5, 0.534);
  height: 48px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border: none;
  padding: 0 24px;
  font-weight: 600;
  font-size: 1.1rem;
  margin-top: 10px;
  margin: auto;
}

.mint-button:hover {
  background-color: rgba(218, 3, 3, 0.753);
}

.connect-button {
  color: white;
  background-color: rgba(170, 5, 5, 0.534);
  height: 48px;
  border-radius: 25px;
  padding: 0 24px;
  font-weight: 600;
  font-size: 1.1rem;
  border: none;
}



.number-button-left {
  background-color: rgb(255, 255, 255);
  border: none;
  height: 48px;
  padding: 0 24px;
  font-size: 1.1rem;
  color: rgb(255, 255, 255);
}

.number-box{
  background-color: rgb(255, 255, 255);
  text-align: center;
  border: none;
  width: auto;
  height: 48px;
  font-size: 1.1rem;
  color: rgb(0, 0, 0);
}

.number-button-right {
  background-color: rgb(255, 255, 255);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border: none;
  height: 48px;
  font-size: 1.1rem;
  color: rgb(255, 255, 255);
}

.number-box {
  width: 100px;
}

.footer {
  margin-top: 10%;
  color: white;
  background-color: transparent;
  height: 56px;
}

.about {
  font-size: 36px;
}

.album-cover {
  background-image: url(./assets/background/background-resize.jpg);
  height: 100vh;
  background-repeat: no-repeat;
  background-position: top center;
  
}

.about-text {
  text-align: center;
  border-radius: 10px;
  width: 72%;
  color: white;
  margin-top: 4%;
  margin: auto;
  margin-bottom: 4%;
  padding-top: 2%;
  font-size: 24px;
}

a {
  text-decoration: none;
  color: white;
}

html {
  scroll-behavior: smooth;
}